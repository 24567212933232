/* eslint-disable no-unused-vars */
import { Dispatch } from "react";

export interface ApiImage {
  url: string;
  alt: string;
}

export interface Author {
  name: string;
  url: string | null;
}

export interface ArticleInterface {
  authors: Author[] | [];
  categories: string | null;
  date: Date | string;
  images?: ApiImage[];
  introduction: string | null;
  raw_story: string;
  source: string;
  story?: string;
  tags?: string[] | null;
  title: string;
  url: string;
}

export interface ArticlesState {
  items: ArticleInterface[];
  filteredItems: ArticleInterface[];
  sorting: string;
  selectedArticleId: string;
  selectedArticle?: ArticleInterface;
  initStartDate: Date;
  initEndDate: Date;
}

export interface AppStore {
  articles: ArticlesState;
  searchQuery: string;
  loading: boolean;
  similarArticlesLoading: boolean;
}

export interface SearchBody {
  search_tags?: string;
  text?: string;
}

export interface StoreContextValue {
  state: AppStore;
  dispatch: Dispatch<Actions>;
  actions: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: (_payload: any) => void;
  };
}

export enum ActionTypes {
  SET_ARTICLES = "SET_ARTICLES",
  SET_FILTERED_ARTICLES = "SET_FILTERED_ARTICLES",
  SET_SORTING = "SET_SORTING",
  SET_SELECTED_ARTICLE_ID = "SET_SELECTED_ARTICLE_ID",
  SET_INIT_START_DATE = "SET_INIT_START_DATE",
  SET_INIT_END_DATE = "SET_INIT_END_DATE",
  SET_SEARCH_QUERY = "SET_SEARCH_QUERY",
  SET_IS_LOADING = "SET_IS_LOADING",
  SET_IS_LOADING_SIMILAR = "SET_IS_LOADING_SIMILAR",
}

export type Actions =
  | {
      type: typeof ActionTypes.SET_ARTICLES;
      payload: ArticleInterface[];
    }
  | {
      type: typeof ActionTypes.SET_FILTERED_ARTICLES;
      payload: ArticleInterface[];
    }
  | {
      type: typeof ActionTypes.SET_SORTING;
      payload: string;
    }
  | {
      type: typeof ActionTypes.SET_SELECTED_ARTICLE_ID;
      payload: string;
    }
  | {
      type: typeof ActionTypes.SET_INIT_START_DATE;
      payload: Date;
    }
  | {
      type: typeof ActionTypes.SET_INIT_END_DATE;
      payload: Date;
    }
  | {
      type: typeof ActionTypes.SET_SEARCH_QUERY;
      payload: string;
    }
  | {
      type: typeof ActionTypes.SET_IS_LOADING;
      payload: boolean;
    }
  | {
      type: typeof ActionTypes.SET_IS_LOADING_SIMILAR;
      payload: boolean;
    };
