/*
 * This file includes boilerplate for incorporating headers, tokens, etc into axios requests that require authentication or CORS issues.
 * */
import axios from "axios";

const axiosSetup = () =>
  axios.create({
    withCredentials: false,
  });
export default axiosSetup;
