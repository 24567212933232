import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app

const theme = createTheme({
  palette: {
    primary: {
      main: "#36A9EB",
    },

    error: {
      main: red.A400,
    },
    text: {
      primary: "#101828",
      secondary: "#475467",
    },
  },
  typography: {
    fontFamily: "Inter",
  },
});

export default theme;
