import React, { createContext, ReactNode, useMemo, useReducer } from "react";
import { initialStore, reducer } from "./reducer";
import { ActionTypes, ArticleInterface, StoreContextValue } from "types/store";

export const StoreContext = createContext<StoreContextValue>(null!);
interface IProps {
  children: ReactNode;
}
const StoreContextProvider: React.FC<IProps> = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(reducer, initialStore);

  // eslint-disable-next-line arrow-body-style
  const actions = useMemo(() => {
    return {
      setSearchQuery: (query: string) => {
        dispatch({
          type: ActionTypes.SET_SEARCH_QUERY,
          payload: query,
        });
      },

      setSorting: (sortingType: string) => {
        dispatch({
          type: ActionTypes.SET_SORTING,
          payload: sortingType,
        });
      },

      setInitStartDate: (date: Date) => {
        dispatch({
          type: ActionTypes.SET_INIT_START_DATE,
          payload: date,
        });
      },

      setInitEndDate: (date: Date) => {
        dispatch({
          type: ActionTypes.SET_INIT_END_DATE,
          payload: date,
        });
      },

      setArticles: (articles: ArticleInterface[]) => {
        dispatch({
          type: ActionTypes.SET_ARTICLES,
          payload: articles,
        });
      },

      setFilteredArticles: (filteredArticles: ArticleInterface[]) => {
        dispatch({
          type: ActionTypes.SET_FILTERED_ARTICLES,
          payload: filteredArticles,
        });
      },

      setSelectedArticleId: (uuid: string) => {
        dispatch({
          type: ActionTypes.SET_SELECTED_ARTICLE_ID,
          payload: uuid,
        });
      },
      setIsLoading: (loading: boolean) => {
        dispatch({
          type: ActionTypes.SET_IS_LOADING,
          payload: loading,
        });
      },
      setIsLoadingSimilar: (loading: boolean) => {
        dispatch({
          type: ActionTypes.SET_IS_LOADING_SIMILAR,
          payload: loading,
        });
      },
    };
  }, []);

  // eslint-disable-next-line arrow-body-style
  const contextValue = useMemo(() => {
    return { state, dispatch, actions };
  }, [state, dispatch, actions]);

  return (
    <StoreContext.Provider value={contextValue}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreContextProvider;
