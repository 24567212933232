// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../../node_modules/@fontsource/roboto/300.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../../node_modules/@fontsource/roboto/400.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../../node_modules/@fontsource/roboto/500.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../../node_modules/@fontsource/roboto/700.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
span {
  color: #101828;
  font-family: "Inter";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: 2.5em;
  font-weight: 700;
}

h2 {
  font-size: 1.5em;
  font-weight: 500;
}

p {
  line-height: 1.8;
  margin: 0;
}

.subtext {
  font-size: 0.85rem;
  color: #777;
}

.microtext {
  font-size: 0.68rem;
  color: #777;
}

.back-to-top {
  bottom: 2rem;
  right: 2rem;
}
.back-to-top-icon {
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/_typography.scss","webpack://./src/Components/UI/ScrollTop.scss","webpack://./src/assets/styles/_variables.scss"],"names":[],"mappings":"AAOA;EACE,qBAAA;ACFF;;ADKA;;;;;;;;;;EAUE,cErBM;EFsBN,oBAAA;ACFF;;ADKA;;;;;;EAME,SAAA;ACFF;;ADKA;EACE,gBAAA;EACA,gBE1BiB;ADwBnB;;ADKA;EACE,gBAAA;EACA,gBEjCmB;AD+BrB;;ADKA;EACE,gBAAA;EACA,SAAA;ACFF;;ADKA;EACE,kBAAA;EACA,WAAA;ACFF;;ADKA;EACE,kBAAA;EACA,WAAA;ACFF;;AAnDA;EACE,YAAA;EACA,WAAA;AAsDF;AArDE;EACE,WAAA;AAuDJ","sourcesContent":["@import \"@fontsource/roboto/300.css\";\n@import \"@fontsource/roboto/400.css\";\n@import \"@fontsource/roboto/500.css\";\n@import \"@fontsource/roboto/700.css\";\n\n$font-stack: \"Roboto\", sans-serif;\n\na {\n  text-decoration: none;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6,\na,\np,\nli,\nspan {\n  color: $black;\n  font-family: \"Inter\";\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  margin: 0;\n}\n\nh1 {\n  font-size: 2.5em;\n  font-weight: $font-weight-bold;\n}\n\nh2 {\n  font-size: 1.5em;\n  font-weight: $font-weight-medium;\n}\n\np {\n  line-height: 1.8;\n  margin: 0;\n}\n\n.subtext {\n  font-size: 0.85rem;\n  color: #777;\n}\n\n.microtext {\n  font-size: 0.68rem;\n  color: #777;\n}\n","@import '../../assets/styles/variables';\n@import '../../assets/styles/typography';\n@import '../../assets/styles/mixins';\n.back-to-top {\n  bottom: 2rem;\n  right: 2rem;\n  &-icon {\n    color: #fff;\n  }\n}\n","$black: #101828;\n$white: #ffffff;\n$off-white: #fcfcfc;\n$orange: #ed6c60;\n$primary: #ed6c60;\n\n$font-weight-light: 300;\n$font-weight-regular: 400;\n$font-weight-medium: 500;\n$font-weight-semibold: 600;\n$font-weight-bold: 700;\n$font-weight-extrabold: 800;\n$font-weight-black: 900;\n\n$xs: 0px;\n$sm: 600px;\n$md: 900px;\n$lg: 1200px;\n$xl: 1536px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
