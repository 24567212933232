/* eslint-disable @typescript-eslint/no-explicit-any */
/*
 * This is the custom hook.
 * It takes an apiCallbackFunction as its argument in the form of an anonymous callback.
 * The useState sets a response object that tracks data, loading status, errors and successful status.
 * It returns the response object along with a fetchMethod function that, when invoked, triggers the asynchronous API call and sets the response.
 * */

import { useState } from "react";

interface Response {
  data: any;
  isFetching: boolean;
  error: Error | null;
  isSuccess: boolean;
}

export function useApi<T extends any[]>(
  // eslint-disable-next-line no-unused-vars
  apiCallbackFunction: (...args: T) => Promise<any>
) {
  const [response, setResponse] = useState<Response>({
    data: null,
    isFetching: false,
    error: null,
    isSuccess: false,
  });

  const fetchMethod = (...args: T): void => {
    setResponse({
      data: null,
      isFetching: true,
      error: null,
      isSuccess: false,
    });
    apiCallbackFunction(...args)
      .then((res) => {
        setResponse({
          ...response,
          data: res,
          isFetching: false,
          isSuccess: true,
          error: null,
        });
      })
      .catch((err) => {
        setResponse({
          ...response,
          data: null,
          isFetching: false,
          isSuccess: false,
          error: err.message,
        });
      });
  };

  return [fetchMethod, response] as const;
}
