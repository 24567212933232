/* eslint-disable no-restricted-globals */
import React, {
  lazy,
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoadingPage from "Pages/LoadingPage";
import { Box } from "@mui/material";
import ScrollTop from "Components/UI/ScrollTop";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "store-context/store";
import { apiEndpoints } from "api";
import { useApi } from "api/useApi";
import { ArticleInterface, SearchBody } from "types/store";
import { toast } from "react-toastify";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

const Header = lazy(() => import("Components/Layout/Header"));

const Footer = lazy(() => import("Components/Layout/Footer"));

const NotFound = lazy(() => import("Pages/NotFound"));

const Hero = lazy(() => import("Pages/Hero"));

const Topic = lazy(() => import("Pages/Topic"));

const App = () => {
  const navigate = useNavigate();
  const store = useContext(StoreContext);

  const [searchArticles, searchArticlesResponse] = useApi<[SearchBody]>(
    (reqBody: SearchBody) => apiEndpoints.searchArticles(reqBody)
  );

  const [_fetchedArticles, _setFetchedArticles] =
    useState<ArticleInterface[]>();

  const didMount = useRef(true);
  useEffect(() => {
    if (!!didMount.current) {
      store.actions.setIsLoading(false);
      didMount.current = false;
      return;
    }

    if (!!searchArticlesResponse) {
      if (searchArticlesResponse.isFetching) {
        store.actions.setIsLoading(true);
      } else if (searchArticlesResponse.isSuccess) {
        if (
          searchArticlesResponse.data &&
          searchArticlesResponse.data.data &&
          searchArticlesResponse.data.data.articles
        ) {
          if (searchArticlesResponse.data.data.articles.length) {
            store.actions.setArticles(
              searchArticlesResponse.data.data.articles
            );
            store.actions.setIsLoading(false);
            navigate("/topic");
          } else {
            store.actions.setIsLoading(false);
            toast.warn(
              "The search returned 0 results. Please search for something else"
            );
          }
        } else if (
          searchArticlesResponse.data &&
          searchArticlesResponse.data.data
        ) {
          if (searchArticlesResponse.data.data.length) {
            searchArticlesResponse.data.data.sort(
              (a: any, b: any) =>
                new Date(b.date).getTime() - new Date(a.date).getTime()
            );

            store.actions.setArticles(searchArticlesResponse.data.data);
            store.actions.setIsLoading(false);
            navigate("/topic");
          } else {
            store.actions.setIsLoading(false);
            toast.warn(
              "The search returned 0 results. Please search for something else"
            );
          }
        }
      } else if (
        !searchArticlesResponse.isSuccess &&
        !!searchArticlesResponse.error
      ) {
        store.actions.setIsLoading(false);
        toast.error("Something went wrong, please try again!");
        console.error(searchArticlesResponse.error);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchArticlesResponse]);

  useEffect(() => {
    if (store.state.searchQuery !== "") {
      if (typeof store.state.searchQuery === "object") {
        // eslint-disable-next-line camelcase
        searchArticles({ search_tags: store.state.searchQuery });
      } else {
        // eslint-disable-next-line camelcase
        searchArticles({ text: store.state.searchQuery });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.state.searchQuery]);

  return (
    <Suspense fallback={<LoadingPage />}>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
      />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="App">
            <Header />
            <Box className="content-pad random-bg" id="back-to-top-anchor">
              <Box className="random-bg-overlay"></Box>
            </Box>

            <Routes>
              <Route
                path="/"
                element={<Hero />}
                // element={store.state.loading ? <LoadingPage /> : <Hero />}
              />
              <Route path="/topic" element={<Topic />} />
              <Route path="*" element={<NotFound />} />
            </Routes>

            <ScrollTop />
            {location.pathname !== "/topic" && <Footer />}
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    </Suspense>
  );
};

export default App;
