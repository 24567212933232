import axios from "axios";
import axiosSetup from "./axiosSetup";
import { toast } from "react-toastify";
import { SearchBody } from "types/store";
const BASE_URL = "https://lynir.com:8443/api/";

const queryURL = "https://lynir.com:8443/api/nlp_query";
const tagURL = "https://lynir.com:8443/api/es_tags_search";
const similarURL = "https://lynir.com:8443/api/similar_articles";
const recentURL = "https://lynir.com:8443/api/latest_news_per_source";

export const apiEndpoints = {
  searchArticles: async (reqBody: SearchBody) => {
    try {
      const url = reqBody.text ? queryURL : tagURL;
      const response = await axiosSetup().post(url, reqBody);
      return response.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        toast.error(
          "Oops! Something went wrong while searching for articles. Please try again"
        );
        console.warn(err);
      }
      throw err;
    }
  },
  recentArticles: async () => {
    try {
      const url = recentURL;
      const response = await axiosSetup().post(url);
      return response.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        toast.error(
          "Oops! Something went wrong while searching for articles. Please try again"
        );
        console.warn(err);
      }
      throw err;
    }
  },
  similarArticles: async (articleURL: string) => {
    try {
      const url = similarURL;
      const response = await axiosSetup().post(url, { url: articleURL });

      return response.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        toast.error(
          "Oops! Something went wrong while searching for articles. Please try again"
        );
        console.warn(err);
      }
      throw err;
    }
  },
};

export async function getSimilarArticles(articleURL: string) {
  const response = await axios.post(BASE_URL + "similar_articles", {
    url: articleURL,
  });

  return response.data;
}
