import { Actions, ActionTypes, AppStore } from "types/store";
import { sortingTypes } from "utils/constants";

const initialStore: AppStore = {
  articles: {
    items: [],
    filteredItems: [],
    sorting: sortingTypes.OLDEST,
    selectedArticleId: "none",
    selectedArticle: undefined,
    initStartDate: new Date(),
    initEndDate: new Date(),
  },
  searchQuery: "",
  loading: false,
  similarArticlesLoading: false,
};

const reducer = (state: AppStore, action: Actions): AppStore => {
  switch (action.type) {
    case ActionTypes.SET_ARTICLES:
      return {
        ...state,
        articles: {
          ...state.articles,
          items: action.payload,
          filteredItems: action.payload,
        },
      };
    case ActionTypes.SET_SORTING:
      return {
        ...state,
        articles: {
          ...state.articles,
          sorting: action.payload,
        },
      };
    case ActionTypes.SET_FILTERED_ARTICLES:
      return {
        ...state,
        articles: {
          ...state.articles,
          filteredItems: action.payload,
        },
      };
    case ActionTypes.SET_SELECTED_ARTICLE_ID:
      return {
        ...state,
        articles: {
          ...state.articles,
          selectedArticleId: action.payload,
        },
      };
    case ActionTypes.SET_INIT_START_DATE:
      return {
        ...state,
        articles: {
          ...state.articles,
          initStartDate: action.payload,
        },
      };
    case ActionTypes.SET_INIT_END_DATE:
      return {
        ...state,
        articles: {
          ...state.articles,
          initEndDate: action.payload,
        },
      };
    case ActionTypes.SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload,
      };
    case ActionTypes.SET_IS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ActionTypes.SET_IS_LOADING_SIMILAR:
      return {
        ...state,
        similarArticlesLoading: action.payload,
      };
    default:
      return { ...initialStore };
  }
};

export { reducer, initialStore };
