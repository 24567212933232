/* eslint-disable camelcase */
import { ArticleInterface } from "types/store";

export const categoryColors = [
  "#4B70FF",
  "#21CE99",
  "#FFDB45",
  "#ED6C60",
  "#795548",
  "#3f51b5",
  "#03a9f4",
  "#ff9800",
  "#00bcd4",
  "#259b24",
  "#afb42b",
  "#e51c23",
  "#e91e63",
  "#009688",
  "#ff5722",
  "#607d8b",
];

export const sortingTypes = {
  OLDEST: "Oldest First",
  NEWEST: "Newest First",
};

export const zeroResults:ArticleInterface = {
  url: "",
  title: "No articles found",
  story: "Search something else",
  raw_story: "Search something else",
  introduction: "",
  authors: [{
    url: "",
    name: "",
  }],
  source: "",
  date: "",
  categories: "",
  tags:[]
};
